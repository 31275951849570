import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, updateApplicationContent } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import HelpTooltip from './HelpTooltip';
import CertificatePreview from './CertificatePreview';
import InfoPopup from './InfoPopup';
import LoadingSpinner from './LoadingSpinner';
import { getApplicationMetadata } from '../../services/Application';
import { commitStep, start } from '../../services/Process';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import ShippingDetails from './ShippingDetails';
import ValidationInfo from './ValidationInfo';
import { validateStep1, validateStep2, validateStep3, validateStep4, validateStep5 } from '../../utils/Validators';

const tempPrice = 1;

const tooltips = ["Załączniki wprowadzone na poszczególnych krokach wniosku. Jeśli chcesz je edytować / dodawać, cofnij się do odpowiedniego kroku.",
    "Otrzymasz od nas elektroniczne świadectwo, które możesz przesłać do dowolnej liczby podmiotów oraz 1 egzemplarz wydruku. W wyjątkowych sytuacjach, Twoi kontrahenci lub podmioty obsługujące transakcję (bank, agencja celna, urząd celny) mogą prosić o papierowe kopie świadectwa opatrzone naszą oryginalną pieczęcią. W takiej sytuacji, podaj ilość kopii. Opłata za każdą kopię wynosi " + (tempPrice > 0 ? tempPrice : "5")  + " zł.",
    "Przedstawiona symulacja kosztu może ulec zmianie w trakcie analizy wniosku przez Operatora, na przykład w przypadku zwiększenia liczby stron na skutek konieczności modyfikacji treści Świadectwa.",
    "Otrzymasz świadectwo w formie elektronicznej (pdf) z kodem QR i możliwością weryfikacji na stronie internetowej Krajowej Izby Gospodarczej, ale możesz także zamówić wydruk na specjalnym druku, tzw. giloszu. Opłata za wydruk wynosi " + (tempPrice > 0 ? tempPrice : "35")  + " zł brutto."];
let statementsDescr = ["Eksportowany towar spełnia kryteria pochodzenia zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 952/2013 (Dziennik Urzędowy Unii Europejskiej L 269 z dnia 9.10.2013 r.) artykuły: 59 do 63. *",
    "Eksportowany towar jest tym samym, który został zakupiony i nie podlegał żadnym przeróbkom *",
    "Wszystkie dokumenty określające pochodzenie towaru znajdują się w naszym posiadaniu i będą udostępnione do wglądu na żądanie Krajowej Izby Gospodarczej oraz będą przechowywane przez okres 5 lat. *",
    "Faktury na zakup surowców i inne dokumenty określające pochodzenie towaru znajdują się w posiadaniu firmy i będą udostępnione do wglądu na żądanie Krajowej Izby Gospodarczej oraz będą przechowywane przez okres 5 lat. *",
    "Uprzedzony/a i świadom/a odpowiedzialności karnej wynikającej z art. 272 i 273 Kodeksu Karnego za wyłudzenie poświadczenia nieprawdy i używanie uzyskanego w ten sposób dokumentu oświadczam, iż na dzień sporządzenia niniejszego wniosku dane w nim zawarte oraz danei informacje wynikające z załączonych do wniosku, dostarczonych do Krajowej Izby Gospodarczej dokumentów, są prawdziwe i aktualne. *",
    "Wywóz towaru następuje z terenu Polski *",
    "Nie ubiegałem się i nie ubiegam się o wydanie świadectwa pochodzenia w urzędzie celnym na partię towaru objętą niniejszym wnioskiem *"];
const defaultChamber = "cc594c69-7d9c-4c6c-9666-3841c3f3e977";
/**
 * Reprezentuje komponent ApplicationStep5.
 * 
 * @param {Object} props - Właściwości komponentu.
 * @param {Function} props.communicationHandler - Funkcja obsługująca komunikację.
 * @param {Function} props.registerCallback - Funkcja rejestracji zwrotnego wywołania.
 * @returns {JSX.Element} Komponent ApplicationStep5.
 */
const ApplicationStep5 = ({ communicationHandler, registerCallback, warningsCallback }) => {
    const { accessToken, checkPermission, userId, userName } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step1, setStep1] = useState(null);
    const [step2, setStep2] = useState(null);
    const [step3, setStep3] = useState(null);
    const [step4, setStep4] = useState(null);
    const [step5, setStep5] = useState(null);
    const [status, setStatus] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [statements, setStatements] = useState([false, false, false, false, false, false, false]);
    const [statementsDescriptions, setStatementsDescriptions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);
    const [chamber, setChamber] = useState();
    const [chamberName, setChamberName] = useState();
    const [printYes, setPrintYes] = useState(null);
    const [additionalPrints, setAdditionalPrints] = useState(0);
    const [readOnly, setReadOnly] = useState(false);
    const [applicantData, setApplicantData] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "", phoneNumber: "" });
    const [shippingData, setShippingData] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "", phoneNumber: "", shippingMethod: "" });
    const [prices, setPrices] = useState([]);
    const [countriesEOG, setCountriesEOG] = useState([]);
    const [price, setPrice] = useState(0);
    const [validators, setValidators] = useState({});
    const [receiverFromEOG, setReceiverFromEOG] = useState(false);

    const [warningPopupTrigger, setWarningPopupTrigger] = useState(0);
    const [errorDescription, setErrorDescription] = useState({Errors: [], Disclaimer: ""});

    const debug = false;

    const validateData = async (application, e) => {
        const errorDescription = {Errors: [], Disclaimer: ""}

        const updateWarnings = async (index, count) => {
            await warningsCallback(index, count);
        };
    
        let validationInfo = [];
        let validationHeaders = [];
        validationInfo.push(validateStep1(application.Step_1.Data));
        validationInfo.push(validateStep2(application.Step_2.Data));
        validationInfo.push(validateStep3(application.Step_3.Data, application.Step_2.Data));
        validationInfo.push(validateStep4(application.Step_4.Data, application.Step_2.Data));
        validationInfo.push(validateStep5(application.Step_5.Data, application.Step_1.Data, application.Step_2.Data));

        let showWarning = false;
        const updatePromises = validationInfo.map((info, index) => {
            if (info.length > 0) {
                validationHeaders.push(`Na ${index + 1}. stronie wniosku stwierdzono następujące błędy (${info.length}):`);
                showWarning = true;
            } else
                validationHeaders.push(`Na ${index + 1}. stronie wniosku nie stwierdzono błędów.`);
            return updateWarnings(index, info.length);
        });
    
        await Promise.all(updatePromises);

        for (let i = 0; i < validationHeaders.length; i++) {
            errorDescription.Errors.push({ Header: validationHeaders[i], Description: validationInfo[i] });
        }
        if (showWarning)
            errorDescription.Disclaimer = "Dane wprowadzone we wniosku wymagają poprawy. Możesz kontynuować wypełnianie wniosku, jednak przed jego wysłaniem konieczna będzie korekta.";

        setErrorDescription(errorDescription);

        if (showWarning) {
            setWarningPopupTrigger(warningPopupTrigger + 1);
            e.preventDefault();
            return false;
        } else {
            setInfoPopupTrigger(infoPopupTrigger + 1);
            return true;
        }
    };

    const handleSave = async (e) => {
        let app = application;
        app.Step_5.Data.statements = statements;
        app.Step_5.Data.remarks = remarks;
        app.Step_5.Data.chamber = chamber;
        try {
            if (printYes != null) {
                app.Step_5.Data.printYes = printYes;
                if (printYes === true) {
                    app.Step_5.Data.shippingMethod = shippingData.shippingMethod;
                    if (shippingData.shippingMethod === "courier") {
                        app.Step_5.Data.ShippingAddress = {
                            name: shippingData.producerName,
                            country: shippingData.producerCountry,
                            street: shippingData.producerStreet,
                            building: shippingData.producerBuilding,
                            appartment: shippingData.producerAppartment,
                            postCode: shippingData.producerPostCode,
                            city: shippingData.producerCity,
                            phoneNumber: shippingData.phoneNumber
                        };
                    }
                }
                app.Step_5.Data.additionalPrints = additionalPrints;
            }
        }
        catch (ex) {
            console.error('Failed to save shipping data:', ex);
        }
        await updateApplicationContent(id, app, userId, accessToken);

        return (await validateData(app, e));
    };

    const fetchPrices = async () => {
        try {
            if (debug) console.log("Pobieranie cennika...");
            const response = await fetch('/priceList.json'); // Ścieżka do pliku JSON
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setPrices(data);
        } catch (error) {
            console.error('Błąd podczas ładowania cennika: ', error);
        }
    };

    const fetchCountriesEOG = async () => {
        try {
            if (debug) console.log("Pobieranie listy krajów EOG...");
            const response = await fetch('/countriesEOG.json'); // Ścieżka do pliku JSON
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCountriesEOG(data);
        } catch (error) {
            console.error('Błąd podczas ładowania listy krajów EOG: ', error);
        }
    };

    const calculatePayment = async () => {
        if (printYes === null) return 0;

        let payment = 0;
        //let receiverFromEOG = false;

        if (prices.length === 0) await fetchPrices();
        if (countriesEOG.length === 0) await fetchCountriesEOG();
        if (debug) console.log("AS5, cP, prices.len: " + prices.length + ", countriesEOG.len: " + countriesEOG.length);

        payment += receiverFromEOG ? prices.find(price => price.id === 1).priceBruttoEU : prices.find(price => price.id === 1).priceBruttoNonEU; // obsługa wniosku
        if (printYes) {
            payment += receiverFromEOG ? prices.find(price => price.id === 3).priceBruttoEU : prices.find(price => price.id === 3).priceBruttoNonEU; // wydruk świadectwa
            payment += receiverFromEOG ? additionalPrints * prices.find(price => price.id === 4).priceBruttoEU : additionalPrints * prices.find(price => price.id === 4).priceBruttoNonEU; // wydruk kopii
            if (shippingData.shippingMethod === "courier")
                payment += receiverFromEOG ? prices.find(price => price.id === 5).priceBruttoEU : prices.find(price => price.id === 5).priceBruttoNonEU; // kurier
        }
        return payment;
    };

    const handleForward = async (e) => {
        e.preventDefault();
        // if (countWarnings() > 0) {
        //     alert("Nie wszystkie pola zostały wypełnione poprawnie.\nProszę sprawdzić formularz.");
        //     return;
        // }

        let valid = false;
        valid = await handleSave(e);
        //await updateApplicationContent();
        if (!valid) return;
        const started = await start(id, accessToken);
        if (started)
            commitStep(id, userName, accessToken);
        navigate('/application/list', { replace: true });
    };

    const handleBack = async (e) => {
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    const handleChamberChange = (e) => {
        setChamber(e.target.value);
    }

    const handleRemarksChange = (e) => {
        setRemarks(e.target.value);
    }

    const handleShippingDataChange = (e) => {
        const tempShippingData = { ...shippingData, [e.target.name]: e.target.value };
        setShippingData(tempShippingData);
    }

    const handleStatementChange = (index, checked) => {
        const updatedStatements = [...statements];
        updatedStatements[index] = checked;
        setStatements(updatedStatements);

        if (!checked) {
            setSelectAll(false);
        } else if (updatedStatements.every(statement => statement)) {
            setSelectAll(true);
        }
    };

    const handleSelectAllStatement = (e) => {
        const isChecked = e.target.checked;
        const updatedStatements = statements.map(() => isChecked);
        setStatements(updatedStatements);
        setSelectAll(isChecked);
    };

    const loadAttachments = async () => {
        try {
            const newAttachments = [];

            // Step 1
            if (step1.signedByAgent) {
                newAttachments.push(...step1.proxyFiles.filter(file => file.id));
                //newAttachments.push(step1.proxyFile);
            }
            if (step1.IE599File.id)
                newAttachments.push(step1.IE599File);

            // Step 2
            step2.Products.forEach(product => {
                if (product.boughtOutsideEU === "true") {
                    if (product.importClearanceFile)
                        newAttachments.push(product.importClearanceFile);
                }
            });

            // Step 3 - do uzupełnienia po rozszerzeniu o dodawanie pliku opisu procesu produkcji
            step3.Processes.forEach(process => {
                newAttachments.push(...process.processFiles.filter(file => file.id));
            });

            // Step 4
            newAttachments.push(...step4.exportInvoiceFiles.filter(file => file.id));
            newAttachments.push(...step4.goodsFiles.filter(file => file.id));
            newAttachments.push(...step4.otherGoodsFiles.filter(file => file.id));
            newAttachments.push(...step4.productFiles.filter(file => file.id));
            if (step4.paymentFile.id) newAttachments.push(step4.paymentFile);

            setAttachments(newAttachments);
        } catch (ex) {
            console.error('Failed to load attachments:', ex);
        }
    };

    const handleRadioDataChange = async (e) => {
        try {
            let app = application;
            if (e.target.id === "printYes") {
                setPrintYes(e.target.checked);
            }
            else {
                setPrintYes(!e.target.checked);
            }
            //await updateApplicationContent(id, app, userId, accessToken);
        }
        catch (ex) {
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setDataLoaded(false);
                fetchCountriesEOG();
                fetchPrices();

                const response = await getApplicationContent(id, accessToken);
                if (response.Step_5) {
                    setApplication(response);

                    if (response.Step_1) {
                        setStep1(response.Step_1.Data);
                        try {
                            setApplicantData({
                                producerName: response.Step_1.Data.applicantName,
                                producerCountry: "PL",
                                producerStreet: response.Step_1.Data.applicantStreet,
                                producerBuilding: response.Step_1.Data.applicantBuilding,
                                producerAppartment: response.Step_1.Data.applicantAppartment,
                                producerPostCode: response.Step_1.Data.applicantPostCode,
                                producerCity: response.Step_1.Data.applicantCity,
                                phoneNumber: response.Step_1.Data.phoneNumber
                            });
                        } catch (error) {
                            console.error('Failed to fetch applicant data:', error);
                        }
                    }

                    if (response.Step_2) {
                        setStep2(response.Step_2.Data);

                        if (statementsDescriptions.length === 0) {
                            let tempStatementsDescr = statementsDescr;

                            if (response.Step_1.Data.exporterIsNotProducer === "true")
                                tempStatementsDescr = statementsDescr.slice(0, 3).concat(statementsDescr.slice(4, 7));
                            else
                                if (response.Step_1.Data.exporterIsProducer === "true") {
                                    let producerOnly = true;
                                    if (response.Step_2.Data.Products) {
                                        response.Step_2.Data.Products.forEach(product => {
                                            if (product.exporter === "true") {
                                                producerOnly = false;
                                            }
                                        });
                                    }
                                    if (producerOnly)
                                        tempStatementsDescr = statementsDescr.slice(0, 1).concat(statementsDescr.slice(2, 7));
                                }

                            setStatementsDescriptions(tempStatementsDescr);
                            for (let i = 0; i < tempStatementsDescr.length; i++) {
                                setValidator("statement_" + i, "false");
                            }
                        }
                    }

                    if (response.Step_3) {
                        setStep3(response.Step_3.Data);
                        setReceiverFromEOG(countriesEOG.filter(country => country.code === step3.receiverCountry).length > 0);
                    }
                    setStep4(response.Step_4.Data);
                    setStep5(response.Step_5.Data);
                    if (debug) console.log(response.Step_5.Data);
                    if (response.Step_5.Data.statements)
                        setStatements(response.Step_5.Data.statements);
                    if (response.Step_5.Data.remarks)
                        setRemarks(response.Step_5.Data.remarks);
                    if (response.Step_5.Data.chamber)
                        setChamber(response.Step_5.Data.chamber);
                    else
                        setChamber(defaultChamber);
                    if (response.Step_5.Data.printYes != null) {
                        setPrintYes(response.Step_5.Data.printYes);
                        if (debug) console.log("AS5, uE, printYes: " + response.Step_5.Data.printYes);
                        if (response.Step_5.Data.printYes === true) {
                            let tempShippingData = shippingData;
                            if (response.Step_5.Data.additionalPrints)
                                setAdditionalPrints(response.Step_5.Data.additionalPrints);
                            else    
                                setAdditionalPrints(0);
                            tempShippingData.shippingMethod = response.Step_5.Data.shippingMethod;
                            if (response.Step_5.Data.shippingMethod === "courier") {
                                try {
                                    tempShippingData.producerName = response.Step_5.Data.ShippingAddress.name;
                                    tempShippingData.producerCountry = response.Step_5.Data.ShippingAddress.country;
                                    tempShippingData.producerStreet = response.Step_5.Data.ShippingAddress.street;
                                    tempShippingData.producerBuilding = response.Step_5.Data.ShippingAddress.building;
                                    tempShippingData.producerAppartment = response.Step_5.Data.ShippingAddress.appartment;
                                    tempShippingData.producerPostCode = response.Step_5.Data.ShippingAddress.postCode;
                                    tempShippingData.producerCity = response.Step_5.Data.ShippingAddress.city;
                                    tempShippingData.phoneNumber = response.Step_5.Data.ShippingAddress.phoneNumber;
                                    if (tempShippingData.phoneNumber === null || tempShippingData.phoneNumber === "")
                                        tempShippingData.phoneNumber = response.Step_1.Data.phoneNumber;
                                } catch (error) {
                                    console.error('Failed to fetch shipping data:', error);
                                }

                                setShippingData(tempShippingData);
                            }
                        }
                        else if (response.Step_5.Data.printYes === false || response.Step_5.Data.printYes === "false") setPrintYes(false);
                    }
                }

                const metadata = await getApplicationMetadata(id, accessToken);
                if (metadata) {
                    setStatus(metadata.status);
                    if (metadata.Chamber)
                        setChamberName(metadata.Chamber.name);
                }

                if (checkPermission("Obsługa wniosków")) {
                    if (!operatorCanEdit(metadata.status))
                        setReadOnly(true);
                }
                else {
                    if (!clientCanEdit(metadata.status))
                        setReadOnly(true);
                }
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            } finally {
                setDataLoaded(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (dataLoaded) {
            loadAttachments();
        }
    }, [dataLoaded, step1, step2, step3, step4, step5]);

    useEffect(() => {
        if (debug) console.log("chamber: " + chamber);
    }, [chamber])

    useEffect(() => {
        if (dataLoaded)
            calculatePayment().then((result) => {
                setPrice(result);
            });
    }, [printYes, additionalPrints, shippingData]);

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const validate = () => {
        for (let i = 0; i < statements.length; i++) {
            if (!statements[i])
                setValidator("statement_" + i, "true");
            else
                setValidator("statement_" + i, "false");
        }
        if (printYes === null)
            setValidator("printYes", "true");
        else {
            setValidator("printYes", "false");

            if (additionalPrints < 0)
                setValidator("additionalPrints", "true");
            else
                setValidator("additionalPrints", "false");

            if (printYes === true || printYes === "true") {
                if (shippingData.shippingMethod === "pickup")
                    setValidator("shippingData", "false");
                else {
                    if (shippingData.shippingMethod === "")
                        setValidator("shippingData", "true");
                    else
                        setValidator("shippingData", "false");

                    if (shippingData.shippingMethod === "courier") {
                        if (shippingData.producerName === "" || shippingData.producerStreet === "" || shippingData.producerBuilding === "" || shippingData.producerPostCode === "" || shippingData.producerCity === "" || shippingData.phoneNumber === "")
                            setValidator("shippingData", "true");
                        else
                            setValidator("shippingData", "false");
                    }
                }
            }
            else
                setValidator("shippingData", "false");
        }
    };

    const countWarnings = () => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        warningsCallback(4, countWarnings());
    }, [validators]);

    useEffect(() => {
        validate();
    }, [statements, printYes, additionalPrints, shippingData]);

    return (
        <div>
            <CardHeader
                text="Podsumowanie" commentId="5-1" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div className='mb-1'>
                        Podsumowanie zawiera wprowadzone we wniosku dane w kolejności ułożenia na świadectwie. Sprawdź czy wszystkie pola są wypełnione zgodnie z Twoją intencją.<br />
                        Jeśli chcesz dokonać zmian, cofnij się do odpowiedniego kroku formularza.<br />
                        Jeśli operator zaakceptuje Twój wniosek, to świadectwo wypełnione na podstawie wprowadzonych danych będzie wyglądać następująco:
                    </div>

                    <div>
                        <CertificatePreview applicationId={id} />
                        {/* <ImageComponent fileId="883c7b86-1667-47b6-9374-f4e89b1d8139" /> */}
                    </div>
                </div>
            </div>

            <CardHeader
                text="Załączniki" commentId="5-2" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />

            <div className='card container-fluid shadow ms-4 px-4 py-3 pb-1 w55'>
                <div className='d-flex flex-column w47'>
                    {dataLoaded ? (
                        attachments && (
                            attachments.map((file, index) => (
                                <div className='pb-2' key={"files_" + index}>
                                    <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="productFiles" index={index} readOnly='true' />
                                </div>
                            )))
                    ) : (
                        <LoadingSpinner text='Pobieranie załączników' />
                    )}
                </div>
            </div>

            <CardHeader
                text="Oświadczenia" commentId="5-3" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <ReadonlyOverlay readonly={readOnly}>
                        <div>
                            {(statementsDescriptions.length > 0) ? (
                                <div>
                                <div className='d-flex flex-row pb-1'>
                                    <input className="form-check-input" type='checkbox' id={"statement_selectAll"} checked={selectAll} onChange={handleSelectAllStatement} />
                                    <label className='form-check-label' htmlFor={"statement_selectAll"}><strong>Zaznacz wszystkie</strong></label>
                                </div>

                                {statementsDescriptions.map((statement, index) => (
                                    <div className='d-flex flex-row pb-1' key={"statement_" + index}>
                                        <input 
                                            className="form-check-input"
                                            type='checkbox'
                                            id={"statement_" + index}
                                            checked={statements[index]}
                                            onChange={(e) => handleStatementChange(index, e.target.checked)}
                                            invalid={validators["statement_" + index]}
                                        />
                                        <label className='form-check-label' htmlFor={"statement_" + index}>{statement}</label>
                                    </div>
                                )
                                )}
                            </div>
                            ) : (
                                <LoadingSpinner text='Pobieranie oświadczeń' />
                            )}

                            {/* <div>
                                <div className='pt-1'>
                                    {chamber || dataLoaded ? (
                                        (status === 1) ? (
                                            <ChamberSelector name="chamber" data={chamber} label="Wniosek będzie obsługiwać" onDataChange={handleChamberChange} required="false" />
                                        ) : (
                                            <div className="form-group">
                                                <label className="form-label">Wniosek będzie obsługiwać</label>
                                                <input className="form-control" type="text" value={chamberName} readOnly />
                                            </div>
                                        )
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </ReadonlyOverlay>

                </div>
            </div>

            <CardHeader
                text="Dostawa i opłaty"
                commentId="5-4"
                commentLocation="Dostawa i opłaty"
                commentVisible={status !== 1}
                communicationHandler={communicationHandler}
                registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <ReadonlyOverlay readonly={readOnly}>
                        <div className='mb-1 d-flex flex-row mb-1'>
                            <label className="form-label"><strong>Czy potrzebujesz wydruk świadectwa? *</strong></label>
                            <HelpTooltip title={tooltips[3]} wide={true} />
                            <ValidationInfo title="Wybór jednej z opcji jest obowiązkowy" visible={validators.printYes}  />
                        </div>

                        <div className='d-flex flex-column'>
                            <div className="form-check form-check-inline mb-1">
                                <input className="form-check-input" type="radio" name="printNo" id="printNo" checked={(["false", false].includes(printYes))} onChange={handleRadioDataChange} />
                                <label className="form-check-label" htmlFor="printNo">NIE - wystarczy mi wersja elektroniczna świadectwa</label>
                            </div>
                            <div className="form-check form-check-inline mb-1">
                                <input className="form-check-input" type="radio" name="printYes" id="printYes" checked={printYes} onChange={handleRadioDataChange} />
                                <label className="form-check-label" htmlFor="printYes">TAK</label>
                            </div>
                            {(printYes && (shippingData && applicantData)) && (
                                <div className='d-flex flex-column ps-2-5'>
                                    <div className='d-flex flex-column w27 mb-1'>
                                        <div className='d-flex flex-row w-100'>
                                            <input className="form-check-input" type="checkbox" id="additionalPrints" checked={additionalPrints > 0} onChange={() => {additionalPrints === 0 ? setAdditionalPrints(1) : setAdditionalPrints(0)}} />
                                            <label className="form-check-label" htmlFor="additionalPrints">Potrzebuję dodatkowe kopie świadectwa</label>
                                        </div>
                                        {additionalPrints > 0 && (
                                            <div>
                                                <div className='d-flex flex-row w-100'>
                                                    <label className="form-label">Liczba dodatkowych kopii świadectwa, poza wydrukiem oryginału *</label>
                                                    <HelpTooltip title={tooltips[1]} wide={true} />
                                                </div>
                                                <select
                                                    className="form-control form-select my-0 h2 rounded-3"
                                                    name="chamberSelect"
                                                    value={additionalPrints}
                                                    onChange={(e) => setAdditionalPrints(e.target.value)}
                                                    required="true"
                                                >
                                                    {Array.from({ length: 20 }, (_, index) => (
                                                        <option key={index + 1} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                    <ShippingDetails
                                        applicant={applicantData}
                                        data={shippingData}
                                        onDataChange={handleShippingDataChange}
                                        invalid={validators.shippingData}
                                    />

                                </div>

                            )}

                            {dataLoaded && (
                                <div className='d-flex flex-row w-100'>
                                    <label className="form-label">Prognozowany koszt wystawienia świadectwa:&nbsp;</label>
                                    <div className='d-flex flex-row'>
                                        {price.toFixed(2)} zł
                                        <HelpTooltip title={tooltips[2]} wide={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ReadonlyOverlay>
                </div>
            </div>

            <CardHeader
                text="Uwagi" commentId="5-5" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div>

                        <ReadonlyOverlay readonly={readOnly}>
                            <div>
                                <label className="form-label">Uwagi dla operatora wniosku</label>
                                <textarea className="form-control my-0 rounded-3" style={{ height: "120px" }} maxLength="280" name="remarks" value={remarks} onChange={handleRemarksChange} placeholder='Uwagi dotyczące wniosku' />
                            </div>
                        </ReadonlyOverlay>
                    </div>
                </div>
            </div>

            <form onSubmit={handleForward}>
                <div className="d-flex flex-row ms-4 mt-3 pb-4 w55">
                    <button className="btn btn-outline-primary rounded-2 me-auto w11 h2" onClick={handleBack}>
                        Wstecz
                    </button>

                    {!readOnly && (
                        <button
                            type="button"
                            className="btn btn-outline-primary rounded-2 w11 h2"
                            onClick={handleSave}
                        >
                            Zapisz wersję roboczą
                        </button>
                    )}

                    {status != null ? (
                        (status === 1 && !readOnly) && (
                            <button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2">
                                Zapisz i wyślij wniosek
                            </button>
                        )
                    ) : (
                        <div className="btn btn-primary rounded-2 ms-1 w11 h2">
                            <LoadingSpinner />
                        </div>
                    )}
                </div>
            </form>

            <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            <InfoPopup
                    title={""}
                    errorDescription={errorDescription}
                    // errorHeaders={errorHeaders}
                    // errorDescriptions={[errorDescriptions]}
                    trigger={warningPopupTrigger}
                    errorMode={true}
                    // actionOnAccept={handleForwardNavigation}
                    allowForward={false}
                />
        </div>
    );
};

export default ApplicationStep5;
