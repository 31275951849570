import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CasePayment.css';
import Payment from '../../services/Payment';
import { commitStep } from '../../services/Process';
import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';

const CasePayment = ({ status, applicationData, displayMode = "Client" }) => {
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [prices, setPrices] = useState([]);
    const [countriesEOG, setCountriesEOG] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState({ Items: [] });
    const [loading, setLoading] = useState(false);
    const [showBankAccount, setShowBankAccount] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [receiverFromEOG, setReceiverFromEOG] = useState(false);
    const {accessToken, userName, userId} = useAuth();

    const debug = false;

    const fetchPrices = async () => {
        try {
            if (debug) console.log("Pobieranie cennika...");
            const response = await fetch('/priceList.json'); // Ścieżka do pliku JSON
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (debug) console.log("Cennik: ", data);
            setPrices(data);
        } catch (error) {
            console.error('Błąd podczas ładowania cennika: ', error);
        }
    };

    const fetchCountriesEOG = async () => {
        try {
            if (debug) console.log("Pobieranie listy krajów EOG...");
            const response = await fetch('/countriesEOG.json'); // Ścieżka do pliku JSON
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (debug) console.log("Kraje EOG: ", data);
            setCountriesEOG(data);
        } catch (error) {
            console.error('Błąd podczas ładowania listy krajów EOG: ', error);
        }
    };

    const fillPaymentDetails = async () => {
        let items = [];
        setReceiverFromEOG(countriesEOG.filter(country => country.code === applicationData.paymentShippingCountry).length > 0);
        let total = 0;
        let totalGross = 0;

        // Fill payment details
        const applicationProcessingFeeDetails = prices.filter(price => price.id === 1)[0];
        if (debug) console.log("Szczegóły opłaty za obsługę wniosku: " + JSON.stringify(applicationProcessingFeeDetails));
        if (debug) console.log("Szczegóły wniosku: " + applicationData.name);

        if (applicationData.status === 6) {
            const applicationCancellationFeeDetails = prices.filter(price => price.id === 6)[0];
            items.push({
                name: applicationCancellationFeeDetails.fullName,
                price: applicationCancellationFeeDetails.priceNetto,
                quantity: 1,
                subtotal: applicationCancellationFeeDetails.priceNetto,
                taxRate: Math.round(((receiverFromEOG ? (applicationCancellationFeeDetails.priceBruttoEU) : (applicationCancellationFeeDetails.priceBruttoNonEU) / applicationCancellationFeeDetails.priceNetto) - 1) * 100),
                subtotalGross: receiverFromEOG ? (applicationCancellationFeeDetails.priceBruttoEU) : (applicationCancellationFeeDetails.priceBruttoNonEU)
            });
            total += applicationCancellationFeeDetails.priceNetto;
            totalGross += receiverFromEOG ? (applicationCancellationFeeDetails.priceBruttoEU) : (applicationCancellationFeeDetails.priceBruttoNonEU);
        }
        else {


            // Podstawowa opłata za obsługę wniosku
            items.push({
                name: applicationProcessingFeeDetails.fullName,
                price: applicationProcessingFeeDetails.priceNetto,
                quantity: 1,
                subtotal: applicationProcessingFeeDetails.priceNetto,
                taxRate: Math.round(((receiverFromEOG ? (applicationProcessingFeeDetails.priceBruttoEU) : (applicationProcessingFeeDetails.priceBruttoNonEU) / applicationProcessingFeeDetails.priceNetto) - 1) * 100),
                subtotalGross: receiverFromEOG ? (applicationProcessingFeeDetails.priceBruttoEU) : (applicationProcessingFeeDetails.priceBruttoNonEU)
            });
            total += applicationProcessingFeeDetails.priceNetto;
            totalGross += receiverFromEOG ? (applicationProcessingFeeDetails.priceBruttoEU) : (applicationProcessingFeeDetails.priceBruttoNonEU);

            // Wydruk na giloszu
            if (applicationData.paymentPrint) {
                let printFeeDetails = prices.filter(price => price.id === 3)[0];
                if (debug) console.log("Szczegóły opłaty za wydruk: " + JSON.stringify(printFeeDetails));
                items.push({
                    name: printFeeDetails.fullName,
                    price: printFeeDetails.priceNetto,
                    quantity: 1,
                    subtotal: printFeeDetails.priceNetto,
                    taxRate: Math.round(((receiverFromEOG ? (printFeeDetails.priceBruttoEU) : (printFeeDetails.priceBruttoNonEU) / printFeeDetails.priceNetto) - 1) * 100),
                    subtotalGross: receiverFromEOG ? (printFeeDetails.priceBruttoEU) : (printFeeDetails.priceBruttoNonEU)
                });
                total += printFeeDetails.priceNetto;
                totalGross += receiverFromEOG ? (printFeeDetails.priceBruttoEU) : (printFeeDetails.priceBruttoNonEU);
            }

            // Dodatkowe opłaty
            if (applicationData.paymentAdditionalPrints > 0) {
                let additionalPrintsFeeDetails = prices.filter(price => price.id === 4)[0];
                if (debug) console.log("Szczegóły opłaty za dodatkowe wydruki: " + JSON.stringify(additionalPrintsFeeDetails));
                items.push({
                    name: additionalPrintsFeeDetails.fullName,
                    price: additionalPrintsFeeDetails.priceNetto,
                    quantity: applicationData.paymentAdditionalPrints,
                    subtotal: additionalPrintsFeeDetails.priceNetto * applicationData.paymentAdditionalPrints,
                    taxRate: Math.round(((receiverFromEOG ? (additionalPrintsFeeDetails.priceBruttoEU) : (additionalPrintsFeeDetails.priceBruttoNonEU) / additionalPrintsFeeDetails.priceNetto) - 1) * 100),
                    subtotalGross: receiverFromEOG ? (additionalPrintsFeeDetails.priceBruttoEU * applicationData.paymentAdditionalPrints) : (additionalPrintsFeeDetails.priceBruttoNonEU * applicationData.paymentAdditionalPrints)
                });
                total += additionalPrintsFeeDetails.priceNetto * applicationData.paymentAdditionalPrints;
                totalGross += receiverFromEOG ? (additionalPrintsFeeDetails.priceBruttoEU * applicationData.paymentAdditionalPrints) : (additionalPrintsFeeDetails.priceBruttoNonEU * applicationData.paymentAdditionalPrints);
            }

            // Koszty dostawy
            if (applicationData.paymentShipping) {
                let shippingFeeDetails = prices.filter(price => price.id === 5)[0];
                if (debug) console.log("Szczegóły opłaty za dostawę: " + JSON.stringify(shippingFeeDetails));
                items.push({
                    name: shippingFeeDetails.fullName,
                    price: shippingFeeDetails.priceNetto,
                    quantity: 1,
                    subtotal: shippingFeeDetails.priceNetto,
                    taxRate: Math.round(((receiverFromEOG ? (shippingFeeDetails.priceBruttoEU) : (shippingFeeDetails.priceBruttoNonEU) / shippingFeeDetails.priceNetto) - 1) * 100),
                    subtotalGross: receiverFromEOG ? (shippingFeeDetails.priceBruttoEU) : (shippingFeeDetails.priceBruttoNonEU)
                });
                total += shippingFeeDetails.priceNetto;
                totalGross += receiverFromEOG ? (shippingFeeDetails.priceBruttoEU) : (shippingFeeDetails.priceBruttoNonEU);
            }
        }
        setPaymentDetails({ Items: items, total: total, totalGross: totalGross });
    };

    const getStatusMessage = (status) => {
        switch (status) {
            case 'paid':
                return 'Wniosek opłacony';
            case 'pending':
                return 'Wniosek nieopłacony';
            case 'failed':
                return 'Płatność nie powiodła się.';
            default:
                return 'Nieznany status płatności.';
        }
    };
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(amount);
    };

    const handlePayment = async () => {
        setPaymentInProgress(true);
        const result = await Payment.order(applicationData.id, userId, paymentDetails.totalGross * 100, accessToken);
        //await commitStep(applicationData.id, userName, accessToken);
        window.location.href = result;
        setPaymentInProgress(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (status === 'pending') {
                    setShowPaymentDetails(true);
                }

                await fetchPrices();
                await fetchCountriesEOG();
            } catch (error) {
                console.error('Failed to fetch payment details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [status]);

    useEffect(() => {
        if (prices.length > 0 && countriesEOG.length > 0) {
            fillPaymentDetails();
        }
    }, [prices, countriesEOG]);

    return (
        <div className='border-top pt-1 mt-1'>
            <div className='w-100 d-flex flex-row align-items-center'>
                <div>
                    <i className={`fs-1 bi bi-${status === 'paid' ? 'check-circle-fill text-success' : 'x-circle-fill text-danger'}`} />
                </div>

                <div className='ms-1'>
                    <strong>{getStatusMessage(status)}</strong><br />
                    {status === "paid" && (
                        <span className='text-black-50'>Zapłacono (brutto): {formatCurrency(paymentDetails.totalGross)}</span>
                    )}
                </div>

                {status === "paid" && (
                    <div className='ms-auto'>
                        <button className='btn btn-outline-primary ms-auto w11 h2' onClick={() => setShowPaymentDetails(!showPaymentDetails)}>{showPaymentDetails ? 'Ukryj' : 'Pokaż'} szczegóły płatności</button>
                    </div>
                )}
            </div>

            {((!loading) && paymentDetails && showPaymentDetails) && (
                <div className="pt-1">
                    <strong>Szczegóły płatności - kraj docelowy {receiverFromEOG ? "z EOG" : ("spoza EOG (" + applicationData.paymentShippingCountry + ")")}</strong>
                    <div className='d-flex flex-column mt-1'>
                        <div className='d-flex flex-row fw-semibold text-end'>
                            <div className='w15 text-start'>
                                <span>Usługa</span>
                            </div>
                            <div className='w5 ms-1'>
                                <span>Ilość</span>
                            </div>
                            <div className='w5 ms-1'>
                                <span>Cena</span>
                            </div>
                            <div className='w7 ms-1'>
                                <span>Suma (netto)</span>
                            </div>
                            <div className='w3 ms-1'>
                                <span>VAT</span>
                            </div>
                            <div className='w7 ms-1'>
                                <span>Suma (brutto)</span>
                            </div>
                        </div>
                        {paymentDetails && paymentDetails.Items.length > 0 && paymentDetails.Items.map((item, index) => (
                            <div key={index} className={'d-flex flex-row text-end text-black-50 ' + (((index % 2) === 1) ? '' : 'bg-light bg-opacity-50')}>
                                <div className='w15 text-start text-black'>
                                    <span>{item.name}</span>
                                </div>

                                <div className='w5 ms-1'>
                                    <span>{item.quantity}</span>
                                </div>

                                <div className='w5 ms-1'>
                                    <span>{formatCurrency(item.price)}</span>
                                </div>

                                <div className='w7 ms-1'>
                                    <span>{formatCurrency(item.subtotal)}</span>
                                </div>

                                <div className='w3 ms-1'>
                                    <span>{item.taxRate}%</span>
                                </div>

                                <div className='w7 ms-1 text-black'>
                                    <span>{formatCurrency(item.subtotalGross)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='d-flex flex-row mt-1'>
                        <div className='w27'>
                            <span>Łączna kwota</span>
                        </div>

                        <div className='w7 ms-1 text-end'>
                            <strong>{formatCurrency(paymentDetails.total)}</strong>
                        </div>

                        <div className='w7 ms-5 text-end'>
                            <strong>{formatCurrency(paymentDetails.totalGross)}</strong>
                        </div>
                    </div>
                </div>
            )}

            {((status === "pending") && (displayMode === "Client")) && (
                <div className='w-100 d-flex flex-row mt-2'>
                {paymentInProgress ? (
                    <button className="btn btn-primary ms-auto my-0 h2 w11" type="button" disabled><LoadingSpinner text='Płatność w toku' /></button>
                ) : (
                    <div className='ms-auto'>
                        <button className="btn btn-primary my-0 h2 w11" type="button" onClick={handlePayment}>Opłać online</button>
                        <button className="btn btn-outline-primary ms-1 my-0 h2 w11" type="button" onClick={() => setShowBankAccount(!showBankAccount)}>Przelew tradycyjny</button>
                    </div>
                )}
                </div>
            )}
            {showBankAccount && (
                <div className='mt-2'>
                
                    <div className='d-flex flex-row mt-1'>
                        <div className='w-100'>
                            <h4>Dane do przelewu</h4>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Kwota</strong>
                        </div>
                        <div className='w25'>
                            <span>{formatCurrency(paymentDetails.totalGross)}</span>
                            <button className="btn ms-1 h1 p-0 tooltip" onClick={() => navigator.clipboard.writeText(paymentDetails.totalGross)}>
                                <span className='bi bi-copy'></span>
                                <span className='tooltiptext'>Skopiuj kwotę</span>
                            </button>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Odbiorca</strong>
                        </div>
                        <div className='w25'>
                            <span>Krajowa Izba Gospodarcza BLCA</span>
                            <button className="btn ms-1 h1 p-0 tooltip" onClick={() => navigator.clipboard.writeText('Krajowa Izba Gospodarcza BLCA')}>
                                <span className='bi bi-copy'></span>
                                <span className='tooltiptext'>Skopiuj odbiorcę</span>
                            </button>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Numer konta</strong>
                        </div>
                        <div className='w25'>
                            <span>PL 94 1160 2202 0000 0000 8397 7832</span>
                            <button className="btn ms-1 h1 p-0 tooltip" onClick={() => navigator.clipboard.writeText('PL 94 1160 2202 0000 0000 8397 7832')}>
                                <span className='bi bi-copy'></span>
                                <span className='tooltiptext'>Skopiuj numer konta</span>
                            </button>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Tytuł</strong>
                        </div>
                        <div className='w25'>
                            <span>Wniosek nr [nr wniosku]</span>
                            <button className="btn ms-1 h1 p-0 tooltip" onClick={() => navigator.clipboard.writeText('Wniosek nr [nr wniosku]')}>
                                <span className='bi bi-copy'></span>
                                <span className='tooltiptext'>Skopiuj tytuł przelewu</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

CasePayment.propTypes = {
    status: PropTypes.oneOf(['paid', 'pending', 'failed']).isRequired,
};

export default CasePayment;