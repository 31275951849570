import React from 'react';
import { useEffect, useState } from 'react';
import "./InfoPopup.css";

function InfoPopup({ title, errorDescription = null, errorHeaders = [], errorDescriptions, trigger, warning = false, errorMode = false, actionOnAccept = undefined, allowForward = false }) {
    const [showAlert, setShowAlert] = useState(false);
    // const errorDescription = {
    //     Errors: [
    //         {
    //             Header: "Błąd 1",
    //             Description: ["Opis błędu 1", "Opis błędu 2", "Opis błędu 3"]
    //         },
    //         {
    //             Header: "Błąd 2",
    //             Description: ["Opis błędu 1a", "Opis błędu 2a", "Opis błędu 3a"]
    //         },
    //         {
    //             Header: "Błąd 3",
    //             Description: ["Opis błędu 1b", "Opis błędu 2b", "Opis błędu 3b"]
    //         }
    //     ],
    //     Disclaimer: "Dane wprowadzone w tym kroku wymagają poprawy. Możesz kontynuować wypełnianie wniosku, jednak przed jego wysłaniem konieczna będzie korekta."
    // }

    function showInfoPopup() {
        setShowAlert(true);
        if ((!warning) && (!errorMode)) {
            setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }
    }

    useEffect(() => {
        if (trigger > 0) {
            showInfoPopup();
        }
    }, [trigger]);

    // Remove trailing newline from title
    const sanitizedTitle = title.endsWith('\n') ? title.slice(0, -1) : title;


    if (errorMode) {
        return (
            <div
                className={`alert alert-danger alert-dismissible d-flex flex-column fixed-top w-25 ms-auto fade ${showAlert ? "elementFromRight" : ""}`}
                style={{ marginTop: '125px', zIndex: showAlert ? "100" : "-100", overflow: 'auto', maxHeight: '80%' }}
                role="alert"
            >
                {errorDescription && (
                    <div className='d-flex flex-column custom-scrollbar' style={{ maxHeight: '50%', overflowY: 'auto' }}>
                            {errorDescription.Errors.map((error, index) => (
                                <div key={index}>
                                    <strong>{error.Header}</strong>
                                    <ul>
                                        {error.Description.map((desc, descIndex) => (
                                            <li key={descIndex} className='text-break fade fade-in'>{desc}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                            {errorDescription.Disclaimer && (
                                <div>
                                    <span className='bi bi-exclamation-triangle-fill'></span>&nbsp;&nbsp;{errorDescription.Disclaimer}
                                </div>
                            )}
                    </div>
                )}
                        {(errorDescription && allowForward) && (
                            <div className='d-flex flex-column h6'>
                                <button className='btn btn-outline-primary w-100 mt-1' onClick={actionOnAccept}>Mimo wszystko chcę przejść dalej</button>
                                <button className='btn btn-outline-primary w-100 mt-1' onClick={() => setShowAlert(false)}>Chcę poprawić błędy</button>
                            </div>
                        )}
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowAlert(false)}
                    aria-label="Close"
                />
            </div>
        );
    }

    return (
        <div
            className={`alert` + (warning === false ? ` alert-success ` : ` alert-warning `) + `alert-dismissible d-flex align-items-center fixed-top w-25 ms-auto fade ${showAlert ? "elementFromRight" : ""}`}
            style={{ marginTop: '125px', zIndex: showAlert ? "100" : "-100" }}
            role="alert"
        >
            <div className='d-flex flex-column'>
                {sanitizedTitle.split('\n').map((line, index) => (
                    <div key={index}>
                        {(index > 0) && (
                            <>&bull;&nbsp;</>
                        )}
                        {line}
                    </div>
                ))}
            </div>
            <button
                type="button"
                className="btn-close"
                onClick={() => setShowAlert(false)}
                aria-label="Close"
            ></button>
        </div>
    );
}

export default InfoPopup;