function validateNIP(nip, required = true) {
    if (!required && !nip)
        return true;

        nip = nip.replace(/[\s-]/g, ''); // Usuwanie spacji i myślników

    if (nip.length !== 10 || !/^\d+$/.test(nip)) {
        return false; // Sprawdzenie, czy NIP ma 10 cyfr
    }

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]; // Wagi dla poszczególnych cyfr NIP
    const controlSum = nip
        .substring(0, 9)
        .split('')
        .reduce((sum, digit, index) => sum + parseInt(digit) * weights[index], 0);

    const controlNumber = controlSum % 11;
    const controlDigit = parseInt(nip[9]);

    return controlNumber === controlDigit;
}

function validateProductName(productName, required = true) {
    if (!required && !productName)
        return true;
    if (!productName || productName.length > 110)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(productName);
}

function validateLettersDigitsSpecChars(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(value);
}

function validateLettersDigits(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 .,\/\\-]+$/;
        return regex.test(value);
}

function validateLetters(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
        return regex.test(value);
}

function validateDigits(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[0-9 ]+$/;
        return regex.test(value);
}

function validateBusinessName(businessName, required = true) {
    if (!required && !businessName)
        return true;
    if (!businessName || businessName.length > 160)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(businessName);
}

function validateStreet(street, required = true) {
    if (!required && !street)
        return true;
    if (!street || street.length > 66)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 .,\/\\-]+$/;
    return regex.test(street);
}

function validateBuilding(building, required = true) {
    if (!required && !building)
        return true;
    if (!building || building.length > 9)
        return false;
    const regex = /^\d+[a-zA-Z\d,[\]/ -]*$/;
    return regex.test(building);
}

function validateAppartment(appartment, required = false) {
    if (!required && !appartment)
        return true;
    if (!appartment || appartment.length > 11)
        return false;
    const regex = /^\d+[a-zA-Z\d,[\]/ -]*$/;
    return regex.test(appartment);
}

function validateCity(city, required = true) {
    if (!required && !city)
        return true;
    if (!city || city.length > 30)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s,-./[\]]+$/;
    return regex.test(city);
}

function validateCountryCode(countryCode, required = true) {
    if (!required && !countryCode)
        return true;
    if (!countryCode || countryCode.length > 2)
        return false;
    const regex = /^[a-zA-Z]/;
    return regex.test(countryCode);
}

function validatePostCode(postCode, required = true, international = false) {
    if (!required && !postCode)
        return true;

    if (!postCode)
        return false;

    // międzynarodowy kod pocztowy
    if (international) {
        if (postCode.length > 11)
            return false;
        return true;
    }

    // walidacja zgodnie z 
    if (!/^\d{2}-\d{3}$/.test(postCode))
        return false;
    return true;
}

function validateEmail(email, required = true) {
    if (!required && !email)
        return true;
    if (!email || email.length > 254)
        return false;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

function validateProduct(product) {
    let errors = [];

    if (!validateProductName(product.productName))
        errors.push(`Nazwa produktu ${product.productName} jest nieprawidłowa.`);
    if (!validateLettersDigits(product.quantity, 10))
        errors.push(`Ilość produktu ${product.productName} jest nieprawidłowa.`);
    if (!validateLettersDigits(product.unit, 30))
        errors.push(`Jednostka miary produktu ${product.productName} jest nieprawidłowa.`);
    if (!validateLettersDigits(product.quantity + product.unit + product.quantityDesc, 38, true))
        errors.push(`Opis ilości (ilość, jednostka, opcjonalne opisowe określenie ilości) produktu ${product.productName} jest nieprawidłowy.`);

    if (!validateDigits(product.tariffCode, 10))
        errors.push(`Kod taryfy celnej produktu ${product.productName} jest nieprawidłowy.`);
    if (!validateCountryCode(product.originCountry))
        errors.push(`Kod kraju pochodzenia produktu ${product.productName} jest nieprawidłowy.`);
    if (!validateCountryCode(product.producerCountry))
        errors.push(`Kod kraju produkcji produktu ${product.productName} jest nieprawidłowy.`);
    if (product.producer === "")
        errors.push(`Wskazanie eksportera lub producenta produktu ${product.productName} jest wymagane.`);
    if (product.producer === "true"){
        if (!validateBusinessName(product.producerName))
            errors.push(`Nazwa producenta produktu ${product.productName} jest nieprawidłowa.`);
        if (!validateStreet(product.producerStreet))
            errors.push(`Ulica producenta produktu ${product.productName} jest nieprawidłowa.`);
        if (!validateBuilding(product.producerBuilding))
            errors.push(`Numer budynku producenta produktu ${product.productName} jest nieprawidłowy.`);
        if (!validateAppartment(product.producerAppartment))
            errors.push(`Numer lokalu producenta produktu ${product.productName} jest nieprawidłowy.`);
        if (!validatePostCode(product.producerPostCode, true, product.producerCountry !== "PL"))
            errors.push(`Kod pocztowy producenta produktu ${product.productName} jest nieprawidłowy.`);
        if (!validateCity(product.producerCity))
            errors.push(`Miasto producenta produktu ${product.productName} jest nieprawidłowe.`);
    }
    if (product.producer === "false"){
        if (product.boughtInEU === "")
            errors.push(`Wskazanie czy produkt ${product.productName} został zakupiony w UE jest wymagane.`);
        if (product.boughtInEU === "false"){
            if (product.importClearanceFile.id === "")
                errors.push(`Dokument potwierdzający odprawę importową produktu ${product.productName} jest wymagany.`);
        }
    }

    return errors;
}

function validateSummary(summary) {
    let errors = [];

    if (!validateLettersDigits(summary.totalQuantity, 33, false))
        errors.push("Łączna ilość produktów / towarów w podsumowaniu jest nieprawidłowa.");
    if (!validateLettersDigits(summary.totalNetWeight, 22, false))
        errors.push("Łączna waga netto produktów / towarów w podsumowaniu jest nieprawidłowa.");
    if (!validateLettersDigits(summary.totalGrossWeight, 20, false))
        errors.push("Łączna waga brutto produktów / towarów w podsumowaniu jest nieprawidłowa.");
    if (!validateLettersDigitsSpecChars(summary.transportDetails, 280, false))
        errors.push("Szczegóły dotyczące transportu w podsumowaniu są nieprawidłowe.");
    if (!validateLettersDigitsSpecChars(summary.productsRemarks, 360, false))
        errors.push("Uwagi dotyczące produktów / towarów w podsumowaniu są nieprawidłowe.");

    return errors;
}

function validateStep2(data) {
    let errors = [];

    data.Products.forEach(product => {
        errors.push(...validateProduct(product));
    });
    
    errors.push(...validateSummary(data.Summary));

    return errors;
}

function validateStep1(data) {
    let errors = [];

    if (data.exportFromPoland !== "true")
        errors.push("Wskazanie czy eksportujesz z Polski jest wymagane.");
    if (!validateLettersDigits(data.displayName, 160, true))
        errors.push("Nazwa robocza wniosku jest nieprawidłowa.");
    if (data.applicantId === "")
        errors.push("Dane eksportera/wnioskodawcy są wymagane.");
    else {
        if (!validateBusinessName(data.applicantName, true))
            errors.push("Nazwa eksportera/wnioskodawcy jest nieprawidłowa.");
        if (!validateNIP(data.applicantNIP, true))
            errors.push("NIP eksportera/wnioskodawcy jest nieprawidłowy.");
        if (!validateStreet(data.applicantStreet, true))
            errors.push("Ulica eksportera/wnioskodawcy jest nieprawidłowa.");
        if (!validateBuilding(data.applicantBuilding, true))
            errors.push("Numer domu eksportera/wnioskodawcy jest nieprawidłowy.");
        if (!validateAppartment(data.applicantAppartment, false))
            errors.push("Numer lokalu eksportera/wnioskodawcy jest nieprawidłowy.");
        if (!validatePostCode(data.applicantPostCode, true))
            errors.push("Kod pocztowy eksportera/wnioskodawcy jest nieprawidłowy.");
        if (!validateCity(data.applicantCity, true))
            errors.push("Miasto eksportera/wnioskodawcy jest nieprawidłowe.");
    }
    if ([true, "true"].includes(data.signedByAgent)){
        if (data.agentId === "")
            errors.push("Dane firmy składającej wniosek są wymagane.");
        else {
            if (!validateNIP(data.agentNIP, true))
                errors.push("NIP firmy składającej wniosek jest nieprawidłowy.");
            if (!validateBusinessName(data.agentName, true))
                errors.push("Nazwa firmy składającej wniosek jest nieprawidłowa.");
            if (!validateStreet(data.agentStreet, true))
                errors.push("Ulica firmy składającej wniosek jest nieprawidłowa.");
            if (!validateBuilding(data.agentBuilding, true))
                errors.push("Numer domu firmy składającej wniosek jest nieprawidłowy.");
            if (!validateAppartment(data.agentAppartment, false))
                errors.push("Numer lokalu firmy składającej wniosek jest nieprawidłowy.");
            if (!validatePostCode(data.agentPostCode, true))
                errors.push("Kod pocztowy firmy składającej wniosek jest nieprawidłowy.");
            if (!validateCity(data.agentCity, true))
                errors.push("Miasto firmy składającej wniosek jest nieprawidłowe.");
        }

        if (data.proxyFiles.length > 0){
            if (data.proxyFiles[0].id === "")
                errors.push("Dokument reprezentacji Eksportera przez firmę Wnioskodawcy jest wymagany.");
        }
        else
            errors.push("Dokument reprezentacji Eksportera przez firmę Wnioskodawcy jest wymagany.");
    }
    if (([false, "false"].includes(data.signedIndependently)) && ([false, "false"].includes(data.signedByProxy)) && ([false, "false"].includes(data.signedByAgent)))
        errors.push("Wskazanie reprezentacji do podpisu wniosku jest wymagane.");
    if (([false, "false"].includes(data.exporterIsProducer)) && ([false, "false"].includes(data.exporterIsNotProducer)))
        errors.push("Wskazanie czy eksporter/wnioskodawca jest producentem jest wymagane.");
    if (([false, "false"].includes(data.transportDispatched)) && ([false, "false"].includes(data.transportNotDispatched)))
        errors.push("Wskazanie czy transport został już wysłany jest wymagane.");
    if ([true, "true"].includes(data.transportDispatched)){
        if (data.IE599File.id === "")
            errors.push("Dokument potwierdzający wywóz towaru jest wymagany.");
    }

    return errors;
}

function validateProductInProcess(product) {
    let errors = [];

    if (!validateProductName(product.name))
        errors.push(`Nazwa produktu ${product.name} jest nieprawidłowa.`);

    return errors;
}

function validateProcess(process) {
    let errors = [];

    process.Products.forEach(product => {
        errors.push(...validateProductInProcess(product));
    });

    if (!validateLettersDigitsSpecChars(process.productionProcessDescription, 1000, (process.processFiles[0].name === "")))
        errors.push(`Wymagane jest uzupełnienie opisu procesu produkcji lub załączenie dokumentu z jego opisem.`);
    return errors;
}

function validateStep3(data, step2data) {
    let errors = [];

    if (!validateLettersDigitsSpecChars(data.receiverName, 160, true))
        errors.push("Nazwa odbiorcy jest nieprawidłowa.");
    if (!validateLettersDigitsSpecChars(data.receiverAddress, 160, true))
        errors.push("Dane adresowe odbiorcy są nieprawidłowe.");
    if (!validateCountryCode(data.receiverCountry, true))
        errors.push("Kraj odbiorcy jest nieprawidłowy.")

    data.Processes.forEach(process => {
        errors.push(...validateProcess(process));
    });

    let productToDescribe = [];
    step2data.Products.forEach(product => {
        if ([true, "true"].includes(product.producer))
            productToDescribe.push(product.productName);
    });

    //usunięcie z listy produktów, które nie wymagają opisu procesu produkcji
    data.Processes.forEach(process => {
        process.Products.forEach(product => {
            const index = productToDescribe.indexOf(product.name);
            if (index > -1) {
                productToDescribe.splice(index, 1);
            }
        });
    });

    if (productToDescribe.length > 0)
        errors.push(`Brak opisu procesu produkcji dla produktów: ${productToDescribe.join(", ")}.`);
    
    return errors;
}

function validateStep4(data, step2data) {
    let errors = [];

    if (data.exportInvoiceFiles.length > 0){
        if (data.exportInvoiceFiles[0].id === "")
            errors.push("Faktura eksportowa lub faktura pro-forma dotycząca transakcji objętej świadectwem jest wymagana.");
    }
    else
        errors.push("Faktura eksportowa lub faktura pro-forma dotycząca transakcji objętej świadectwem jest wymagana.");

    if (["", null, undefined].includes(data.exportInvoiceValue))
        errors.push("Wskazanie wartości eksportu jest wymagane.");
    if (["", null, undefined].includes(data.exportInvoiceCurrency))
        errors.push("Wskazanie waluty jest wymagane.");

    let productToAttachFilesTo = [];
    step2data.Products.forEach(product => {
        if ([true, "true"].includes(product.exporter)){
            let found = false;
            data.goodsFilesConnections.forEach(connection => {
                connection.forEach(connectedProduct => {                 
                    if (connectedProduct.productName === product.productName)
                        found = true;
                });
            });
            if (!found)
                productToAttachFilesTo.push(product.productName);
        }
    });

    if (productToAttachFilesTo.length > 0)
        errors.push(`Brak załączonych faktur zakupu do towarów: ${productToAttachFilesTo.join(", ")}.`);

    return errors;
}

function validateStep5(data, step1data, step2data) {
    let errors = [];
    let statementsToCheck = 7;
    if (step1data.exporterIsNotProducer === "true")
        statementsToCheck = 6;
    else if (step1data.exporterIsProducer === "true") {
        let producerOnly = true;
        if (step2data.Products) {
            step2data.Products.forEach(product => {
                if (product.exporter === "true") {
                    producerOnly = false;
                }
            });
        }
        if (producerOnly)
            statementsToCheck = 6;
    }
    let allStatementsChecked = true;
    for (let i = 0; i < statementsToCheck; i++) {
        if (data.statements[i] === false) {
            allStatementsChecked = false;
        }
    }
    if (!allStatementsChecked)
        errors.push("Zaznaczenie wszystkich oświadczeń jest wymagane.");

    if (!(["true", true, "false", false].includes(data.printYes)))
        errors.push("Określenie czy potrzebujesz wydruk świadectwa jest wymagane.");
    if (["true", true].includes(data.printYes))
        if (!(["courier", "pickup"].includes(data.shippingMethod)))
            errors.push("Określenie formy dostawy świadectwa jest wymagane.");
        else if (data.shippingMethod === "courier") {
            if (!validateBusinessName(data.ShippingAddress.name))
                errors.push("Nazwa odbiorcy jest nieprawidłowa.");
            if (!validateStreet(data.ShippingAddress.street))
                errors.push("Ulica odbiorcy jest nieprawidłowa.");
            if (!validateBuilding(data.ShippingAddress.building))
                errors.push("Numer domu odbiorcy jest nieprawidłowy.");
            if (!validateAppartment(data.ShippingAddress.appartment))
                errors.push("Numer lokalu odbiorcy jest nieprawidłowy.");
            if (!validatePostCode(data.ShippingAddress.postCode))
                errors.push("Kod pocztowy odbiorcy jest nieprawidłowy.");
            if (!validateCity(data.ShippingAddress.city))
                errors.push("Miasto odbiorcy jest nieprawidłowe.");
            if (data.ShippingAddress.phoneNumber === "")
                errors.push("Numer telefonu dla kuriera jest wymagany.");
        }

    return errors;
}

export {
    validateNIP,
    validateBusinessName,
    validateStreet,
    validateBuilding,
    validateAppartment,
    validateCity,
    validateCountryCode,
    validatePostCode,
    validateProductName,
    validateLettersDigits,
    validateLettersDigitsSpecChars,
    validateDigits,
    validateLetters,
    validateEmail,
    validateStep1,
    validateStep2,
    validateStep3,
    validateStep4,
    validateStep5
};