import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';
import LoadingSpinner from './LoadingSpinner';
import ValidationInfo from './ValidationInfo';
import { getConsentDetails, revokeConsent } from '../../services/Consent';

function Consent({ className, consentId }) {
    const [consentDetails, setConsentDetails] = useState(null);
    const { accessToken } = useAuth();
    let consentTypes = ["Zgoda RODO", "Zgoda handlowa"];
    let navigate = useNavigate();

    const handleAccept = (consentType) => (e) => {
        e.preventDefault();
        navigate('/account/consents/add/' + consentType, { replace: true });
    };

    const handleRevoke = async (e) => {
        e.preventDefault();
        const revoked = await revokeConsent(consentId, accessToken);
        if (revoked) {
            setConsentDetails(prevDetails => ({
                ...prevDetails,
                Revoked: new Date().toISOString()
            }));
        }   
    };

    useEffect(() => {
        const fetchConsentDetails = async () => {

            const consent = await getConsentDetails(consentId, accessToken);
            setConsentDetails(consent);
        };

        fetchConsentDetails();
    }, [consentId, accessToken]); // Zależności useEffect

    if (!consentDetails) {
        return (
            <LoadingSpinner />
        )
    }
    else {
        return (
            <div className={className + ' card bg-light container-fluid p-2 ' + (consentDetails.Revoked ? 'border-2 border-danger' : '')}>
                <div className="d-flex flex-row">
                    <h4>{consentTypes[consentDetails.Type - 1]}</h4>
                    &nbsp;<ValidationInfo visible={consentDetails.Revoked ? "true" : "false"} title="Wyrażenie zgody jest niezbędne do korzystania z systemu" />
                </div>

                <div className="mb-2">{consentDetails.Content}</div>
                {
                    (!consentDetails.Revoked) ? (
                        <form onSubmit={handleRevoke}>
                            <div className="d-flex flex-row">
                                <div className='align-self-end w7'>Zaakceptowano: </div>
                                <div className='ms-1 align-self-end'><strong>{new Date(consentDetails.CreateDate).toLocaleString()}</strong></div>
                                <div className='ms-auto'>
                                    <button type="submit" className="btn btn-outline-primary w7 ms-auto">
                                        Wycofaj
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="d-flex flex-row">
                            <div className='align-self-end w7'>Wycofano: </div>
                            <div className='ms-1 align-self-end'><strong>{new Date(consentDetails.Revoked).toLocaleString()}</strong></div>
                            <div className='ms-auto'>
                                <button type="submit" className="btn btn-primary w7" onClick={handleAccept(consentDetails.Type)}>
                                    Zaakceptuj
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default Consent;