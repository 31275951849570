import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import CardHeader from '../components/common/CardHeader';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { commitStep } from '../services/Process';

function PaymentFinish() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const {applicationId} = useParams();
    const {accessToken, userName} = useAuth();

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        const fetchData = async () => {
            await commitStep(applicationId, userName, accessToken);
            navigate(`/application/edit/1/${applicationId}`);
        };

        fetchData();
        setLoading(false);

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row h-100 ">
            <Sidebar />
            <div className='w-100 overflow-auto'>
                <CardHeader text="Płatność zakończona" onlyText="true" />
                {loading ? (
                    <LoadingSpinner text='Płatność jest przetwarzana' />
                ) : (
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-row align-items-center mb-1'>
                                <h4 className='mb-0'>Dziękujemy za dokonanie płatności</h4>
                            </div>
                            <LoadingSpinner text='Trwa przekierowanie na stronę wniosku' />
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
}

export default PaymentFinish;
