import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ApiHost } from '../utils/ApiConfig';
import { getConsentText, acceptConsent } from '../services/Consent';
import CardHeader from '../components/common/CardHeader';
import LoadingSpinner from '../components/common/LoadingSpinner';

const AddConsent = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    let navigate = useNavigate();
    let { consentType } = useParams();
    let consentTypes = ["Zgoda RODO", "Zgoda handlowa"];
    const [consentText, setConsentText] = useState('');

    // if (!isLoggedIn)
    //     navigate('/Login', { replace: true });

    const handleAccept = async (e) => {
        e.preventDefault();
        const result = await acceptConsent(consentType, userId, accessToken);
        if (result) {
            navigate('/user/profile', { replace: true });
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/user/profile', { replace: true });
    };

    useEffect(() => {
        const fetchData = async () => {
            const cText = await getConsentText(consentType);
            setConsentText(cText);
        };

        fetchData();
    }, [userId, accessToken]);


    console.log("Akceptacja zgody: " + consentType);

    return (
        <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
            <div className="d-flex flex-column">
                <Sidebar />
            </div>
            <div className="d-flex flex-fill flex-column">
                <CardHeader text={"Akceptacja zgody"} onlyText="true" />
                <div className='card container-fluid shadow mx-4 px-4 py-3 w55'>
                    <h4>{consentTypes[consentType - 1]}</h4>
                    {(consentText === '') && (
                        <LoadingSpinner />
                    )}
                    {(consentText !== '') && (
                        <div>
                            <div className="mb-2">{consentText}</div>
                            <div className="flex-column">
                                <form onSubmit={handleAccept}>
                                    <div className="d-flex flex-row flex-wrap">
                                        <button className="btn btn-outline-primary rounded-2 me-auto w7 h2" onClick={handleCancel}>
                                            Anuluj
                                        </button>
                                        <button type="submit" className="btn btn-primary rounded-2 ms-auto w7 h2">
                                            Zaakceptuj
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </main>
    );
};

export default AddConsent;
