import React, { useState, useEffect } from 'react';
import ProductSelector from './ProductSelector';

function FileProductsConnector({ index, data = [], productsList, fileType, onDataChange, readOnly = "false" }) {
    const [products, setProducts] = useState(productsList);
    const [selectedProduct, setSelectedProduct] = useState();
    const [connectedProducts, setConnectedProducts] = useState([]);

    const handleSelect = (e) => {
        setSelectedProduct(e);
    }

    const handleAdd = (e) => {
        setConnectedProducts(prevProducts => {
            return [...prevProducts, { productName: selectedProduct }];
        });

        setProducts(prevProducts => {
            return prevProducts.filter((product) => product.productName !== selectedProduct);
        });

        onDataChange([...connectedProducts, { productName: selectedProduct }], fileType, index);
    }

    const handleRemove = (productName) => {
        setConnectedProducts(prevProducts => {
            return prevProducts.filter((product) => product.productName !== productName);
        });

        setProducts(prevProducts => {
            return [...prevProducts, { productName: productName }];
        });

        setSelectedProduct("");
        onDataChange(connectedProducts.filter((product) => product.productName !== productName), fileType, index);
    }

    useEffect(() => {
        if (data) {
            setConnectedProducts(data);
            data.forEach(product => {
                setProducts(prevProducts => {
                    return prevProducts.filter((prod) => prod.productName !== product.productName);
                });
                // });}
            });
        }
    }, [])

    useEffect(() => {
    }, [products]);

    if (connectedProducts.length === 0 && readOnly === "true") return null;

    return (
        <div className='w-100'>
            {(((products.length > 0) && (readOnly === "false")) ? (
            <div className='d-flex flex-row' style={{verticalAlign: "bottom"}}>
                <div className='flex-fill'>
                    <ProductSelector label="Wybierz towary, których dotyczy ten dokument" productsList={products} data={selectedProduct} onDataChange={handleSelect} />
                </div>
                <button type="button" className="btn btn-outline-primary rounded-2 mt-auto mb-0 ms-1 w7 h2" onClick={handleAdd}>
                    Dodaj
                </button>
            </div>
            ) : (
            <div className='d-flex flex-row mb-1'>
                Towary, których dotyczy ten dokument
            </div>
            ))
            }

            <div className='d-flex flex-wrap'>
                {connectedProducts.length > 0 ? (
                    connectedProducts.map((product, index) => (
                        <div key={"connectedProduct_" + index} className='d-flex flex-row btn btn-outline-primary border rounded-2 mb-1 me-1'>
                            {product.productName}
                            {(readOnly === "false") && (
                                <button type="button" className="btn btn-outline-danger p-0 rounded-2 align-self-start ms-1 z-2" style={{ width: '20px', border: "0" }} onClick={() => handleRemove(product.productName)} title="Usuń z listy">
                                    X
                                </button>
                            )}
                        </div>
                    ))
                ) : (
                    <div className='my-1'>
                        Brak połączonych towarów.
                    </div>
                )}
            </div>
        </div>
    );
}

export default FileProductsConnector;
