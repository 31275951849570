import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const debug = false;
let consentTypes = ["Zgoda RODO", "Zgoda handlowa"];

const getUserConsentIds = async (userId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie listy zgód dla użytkownika: " + userId)
        const response = await axios.post(ApiHost + '/users/consent/ids', {
            user_id: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        if (response.status === 200)
            return response.data.consentIds;
        return [];
    } catch (error) {
        console.error('Błąd dostępu do chronionej metody', error);
    }
};

const getConsentDetails = async (consentId, accessToken) => {
    try {
        if (debug) console.log("Pobieranie danych dla consentId: " + consentId);
        const response = await axios.post(ApiHost + `/users/consent/details`, {
            consent_id: consentId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        if (response.status === 200)
            return response.data.consent;
        return null;
    } catch (error) {
        console.error("Błąd podczas pobierania szczegółów zgody:", error);
    }
};

const revokeConsent = async (consentId, accessToken) => {
    try {
        const response = await axios.post(ApiHost + `/users/consent/revoke`, {
            consent_id: consentId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200)
            return true;
        return false;
    } catch (error) {
        console.error("Błąd podczas pobierania szczegółów zgody:", error);
    }
};

const acceptConsent = async (consentType, userId, accessToken) => {
    try {
        const response = await axios.post(ApiHost + `/users/consent/add`, {
            consent_type: consentType,
            user_id: userId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) 
            return true
        return false;
    } catch (error) {
        console.error("Błąd podczas dodawania zgody:", error);
    }
};

const getConsentText = async (consentType, accessToken) => {
    try {
        if (debug) console.log("Pobieranie tekstu zgody")
        const response = await axios.post(ApiHost + '/users/consent/get_text', {
            consent_type: consentType
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        if (response.status === 200)
            return response.data.consent_text;
        return null;
    } catch (error) {
        console.error('Błąd dostępu do chronionej metody', error);
    }
};

export { 
    getConsentDetails, 
    getUserConsentIds,
    revokeConsent, 
    acceptConsent, 
    getConsentText 
};